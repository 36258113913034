// 解决方案 子页面
<template>
  <div class="solution-details">
    <Bigimg
      title="安全服务"
      :imgUrl="require('@/assets/images/aqfw.jpg')"
    ></Bigimg>
    <div class="solution-details-list wrap-v1"></div>
  </div>
</template>
<script>
import imgLeftorright from "@/components/img-leftorright";
import Bigimg from "@/components/bigimg.vue";

export default {
  components: { imgLeftorright, Bigimg },
  data() {
    return {
      title: "空中课堂方案介绍",
      desc:
        "物联网是物物相通，而智能物联则是指除了物物相通外，智能物联不仅仅是网络、应用与管理，而且是面向业务的智能化的应用与服务。物联网是物物相通，而智能物联则是指除了物物相通外，智能物联不仅仅是网络、应用与管理，而且是面向业务的智能化的应用与服务。物联网是物物相通，而智能物联则是指除了物物相通外，智能物联不仅仅是网络、应用与管理，而且是面向业务的智能化的应用与服务。",
      imgUrl1: require("../../assets/images/cpzs_1.png"),
      imgUrl2: require("../../assets/images/cpzs_2.jpg")
    };
  },
  methods: {
    goDetail() {
      alert("资料不完善，试试其他的吧");
    }
  }
};
</script>
<style scoped lang="scss">
.solution-details {
  .solution-details-list {
    padding: 60px 0 130px;
  }
}
</style>
